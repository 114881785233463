import './styles/HomeCSS.css';
import React from 'react';

const Home = () => {
return (
<div className="home">
<h1 className='normFont'>Use <b className='coloredname'>Javascript</b> With ❤️</h1>
<p><b className='Bold'>Open</b> Source <b className='Bold'>Userscripts🤖</b> .IO Cheats, We <b className='Bold'>Provide</b>:</p>
<section className='t'>
<div className='bgicon1'></div>
  <div className='card4script'>
    <h2>Krunker.Io</h2>
    <p><b className='Bold'>Features:</b> Aimbot, ESP, Charms, Wireframe, Cam, & SpinBot</p>
  </div>
  <div id='scripts' className='card4script'>
    <h2>Ev.Io</h2>
    <p><b className='Bold'>Features:</b> Aimbot, ESP, & Wireframe</p>
    
  </div>
  <div className='card4script'>
    <h2>SmashKarts.Io</h2>
    <p><b className='Bold'>Features:</b> ESP, & Charms</p>
  </div>
  <div className='card4script'>
    <h2>Voxiom.Io</h2>
    <p><b className='Bold'>Features:</b> ESP, Charms, & Wireframe</p>
  </div>
  </section>
  <div className='learnmorecard'>
    <div className='icons8-trust'></div>
    <h1 style={{position: 'relative', top: '-30px'}} className='normFont'><b className='Bold'>Development:</b></h1>
    <p style={{position: 'relative', top: '-30px', color: 'gray', width:'80%', textAlign: 'center', margin: 'auto' }}>Sed id consectetur arcu.

Praesent auctor, erat eget finibus vestibulum, ipsum dui efficitur sapien, a gravida tortor metus et nunc. Nulla at nisi ullamcorper, laoreet ante ac, volutpat risus. Integer quis felis ac nisi sodales cursus vel ac ligula. Aliquam erat volutpat. Sed eget metus in dui mattis dapibus. Proin sed mauris ac ante tristique rutrum. Phasellus vitae nunc lectus. Nunc vel tincidunt lorem. Etiam id sapien vitae erat lacinia lacinia. Sed id massa ut nisi rutrum maximus eget vitae felis. Integer at diam in eros posuere dapibus. Phasellus porta orci eu dolor consectetur, ut bibendum libero venenatis. Vivamus et elit vitae velit fermentum laoreet sit amet ac ipsum.</p>
    <div className='geomatery' style={{  width:'190px', height:'190px', top: '-450px', left: '-130px', background: 'radial-gradient(circle at 30% 30%, #EE82EE, #8000FF)'}}></div>
    <div className='geomatery' style={{  width:'210px', height:'210px', top: '-200px', left: '500px', background: 'radial-gradient(circle at 30% 30%, #00FF00, #00B200)'}}></div>
  </div>
  <div id='about' className='learnmorecard'>
  <div className='icons8-about'></div>
    <h1 style={{position: 'relative', top: '-40px'}} className='normFont'><b className='Bold'>About Us:</b></h1>
    <p style={{position: 'relative', top: '-40px', color: 'gray', width:'80%', textAlign: 'center', margin: 'auto' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at tortor nec nisi facilisis scelerisque. Mauris vitae risus libero. Nullam commodo massa sit amet tellus ultricies, sit amet sollicitudin arcu dictum. Proin sed tellus ut risus laoreet gravida. Nullam non libero euismod, consectetur libero id, feugiat ipsum. Vivamus non neque in ante pretium ultrices. Morbi lobortis augue id ligula fermentum, eu aliquet est cursus. Integer auctor fermentum magna, vel suscipit risus dignissim id. Phasellus pretium lorem eget lacus aliquet, eget vestibulum neque molestie.</p>
  </div>
  <div className='learnmorecard'>
    <section>
    <h2><b className='pointMakingh'>25k+</b> Users</h2>
    <h3><b className='pointMakingh'>632+</b> Daily</h3>
    </section>
    <div className='geomatery' style={{ width: '190px', height: '190px', top: '-400px', left: '-100px', borderRadius: '50%', background: 'radial-gradient(circle at 30% 30%, #FF6B6B, #ff3d3d, #f9ffc2)'}}></div>
<div className='geomatery' style={{ width: '200px', height: '200px', top: '-80px', left: '-50px', borderRadius: '50%', background: 'radial-gradient(circle at 30% 30%, #ff6600, #ff9933, #ffdd77)'}}></div>
<div className='geomatery' style={{ width: '220px', height: '220px', top: '-530px', left: '500px', borderRadius: '50%', background: 'radial-gradient(circle at 30% 30%, #FFFF00, #FFD700, #FFA500)'}}></div>

  </div>
  
<br/>

<br/>
</div>
);
}

export default Home;
